import axios from "axios";

export default function (accessToken = null) {
  const base_url = process.env.VUE_APP_LMS_API;

  return {
    async getMyLeads(params) {
      return await axios.get(`${base_url}/my-lead/all`, {
        params,
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getSingleLead(id) {
      return await axios.get(`${base_url}/my-lead/single/` + id, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllFollowUps(id) {
      return await axios.get(`${base_url}/my-lead/follow-ups/` + id, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAssigneeTimeline(id) {
      return await axios.get(`${base_url}/my-lead/assignee-timeline/` + id, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getCategoryFiltersAndValues(params) {
      return await axios.get(`${base_url}/my-lead/category-fields-values`, {
        params,
        headers: {
          authorization: accessToken,
        },
      });
    },

    async saveLead(payload) {
      return await axios.post(`${base_url}/my-lead/save`, payload, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async sendStudentMail(payload) {
      return await axios.post(`${base_url}/my-lead/mail/student`, payload, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllStates() {
      return await axios.get(`${base_url}/my-lead/master/states`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllCities() {
      return await axios.get(`${base_url}/my-lead/master/cities`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllLeadStages() {
      return await axios.get(`${base_url}/my-lead/master/lead-stages`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllLeadSubStages() {
      return await axios.get(`${base_url}/my-lead/master/lead-sub-stages`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllCategories() {
      return await axios.get(`${base_url}/my-lead/master/lead-categories`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllUsers() {
      return await axios.get(`${base_url}/my-lead/master/users`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllTeamMembers() {
      return await axios.get(`${base_url}/my-lead/master/team-members`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllCategoryFields() {
      return await axios.get(`${base_url}/my-lead/master/lead-category-fields`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllLeadSources() {
      return await axios.get(`${base_url}/my-lead/master/lead-sources`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllMediums() {
      return await axios.get(`${base_url}/my-lead/master/lead-mediums`, {
        headers: {
          authorization: accessToken,
        },
      });
    },

    async getAllCampaigns() {
      return await axios.get(`${base_url}/my-lead/master/lead-campaigns`, {
        headers: {
          authorization: accessToken,
        },
      });
    },
  };
}
