<template>
  <app-timeline class="w-100">

    <app-timeline-item v-for="(item, index) in applicationTimeline" :key="'at' + index" :title="`${item.status_name}`"
      :subtitle="`
      <h6 class=''>${item.sub_status_name && 'Sub Status: ' + item.sub_status_name
        || ''}</h6>
      <h6>${item.follow_up_date && 'Follow Up Date: ' + moment(item.follow_up_date).subtract('5',
          'hours').subtract('30', 'minutes').format('DD MMM, YYYY - hh:mm A') || ''}</h6>
      <h6>${item.comment ? 'Remark: ' + item.comment : ''}</h6>
      `" :time="getDateTimeFormatted(item.created_at)" x1 />
  </app-timeline>
</template>

<script>
import AppTimeline from "./AppTimeline.vue";
import AppTimelineItem from "./AppTimelineItem.vue";
import helpers from "@/libs/helpers";
import CommonServices from "@/apiServices/CommonServices";
import OperationsServices from "@/apiServices/OperationsServices";
import moment from 'moment';
import {
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    BFormSelectOption,
  },
  props: {
    applicationTimeline: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    getDateTimeFormatted: helpers.getDateTimeFormatted,
    moment
  },
};
</script>
<style></style>
